<script>
    import { onMount } from 'svelte';

    export let loggedIn = false;
    export let user = null;

    let showMenu = false;

    function goToUrl(url) {
        window.location.href = url;
    }

    function toggleMenu(event) {
        showMenu = !showMenu;
        event.stopPropagation();
    }

    function closeMenu() {
        showMenu = false;
    }

    onMount(() => {
        document.addEventListener('click', closeMenu);
    });

    $: if (showMenu) {
        document.addEventListener('click', closeMenu);
    } else {
        document.removeEventListener('click', closeMenu);
    }
</script>

<style>
    .navbar-container {
        max-width: 2000px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
    }

    .logo {
        padding: 5px;
        left: 10px;
        top: 10px;
        height: 35px;
        cursor: pointer;
    }

    .img-profile {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        object-fit: cover;
    }

    .name-profile {
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
        padding-bottom: 7px;
    }

    .btn-noborder {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 4px;
        padding: 8px 16px;
        text-align: center;
        text-decoration: none;
        cursor: pointer;
        border: none;
        background-color: transparent;
        color: #606266 !important;
        position: relative;
    }

    .btn-primary {
        display: inline-block;
        border-radius: 4px;
        padding: 8px 16px;
        cursor: pointer;
        text-align: center;
        border: 1px solid #007bff;
        background-color: #007bff;
        color: white !important;
        margin-left: 5px;
    }

    .navbar {
        display: none;
    }

    .menu-icon {
        display: block;
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .menu-icon div {
        width: 25px;
        height: 3px;
        background-color: #606266;
        margin: 4px 0;
    }

    .dropdown-menu {
        display: block;
        position: absolute;
        top: 100%;
        right: 10px;
        background-color: white;
        border-radius: 5px;
        box-shadow: 0 2px 10px rgba(0,0,0,0.1);
        z-index: 1001;
        margin-top: 0px;
        border-radius: 4px;
        padding-left: 5px;
        padding-right: 5px;
    }

    .dropdown-item {
        padding: 8px 16px;
        cursor: pointer;
        text-align: left;
        text-align: left;
    }

    .dropdown-item:hover {
        background-color: #f1f1f1;
    }

    @media (min-width: 768px) {
        .navbar {
            display: flex;
            flex-direction: row;
            float: right;
        }
        .menu-icon {
            display: none;
        }
    }
</style>

<div class="navbar-container">
    <img class="logo" src="/images/logo_w_name.svg" alt="logo" on:click={() => goToUrl('/')}>
    
    <div class="navbar">
        <button class="btn-noborder" on:click={() => goToUrl('/chat')}>Features</button>
        <button class="btn-noborder" on:click={() => goToUrl('/bookshelf')}>Bookshelf</button>
        <button class="btn-noborder" on:click={() => goToUrl('/pricing')}>Pricing</button>
        <button class="btn-noborder" on:click={() => goToUrl('/about')}>About</button>
        {#if loggedIn && user}
            <div class="btn-noborder" on:click|stopPropagation={toggleMenu}>
                <img class="img-profile" src={user.profile_image} alt="Profile Image" />
                <div class="name-profile">{user.name}</div>
                {#if showMenu}
                    <div class="dropdown-menu" on:click|stopPropagation>
                        <div class="dropdown-item" on:click={() => goToUrl('/dashboard')}>Account</div>
                        <div class="dropdown-item" on:click={() => goToUrl('/logout')}>Log out</div>
                    </div>
                {/if}
            </div>
        {:else}
            <button class="btn-primary" on:click={() => goToUrl('/login')}>Sign in</button>
        {/if}
    </div>
</div>
