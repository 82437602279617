<script>
    export let loggedIn = false;
    export let user = null;
    import { onMount } from 'svelte';
  
    let isOpen = false;
    let userMenuOpen = false;
  
    function toggleMenu() {
        isOpen = !isOpen;
    }
  
    function closeMenu() {
        isOpen = false;
    }
    
    function goToUrl(url) {
        window.location.href = url;
    }
  
    function toggleUserMenu() {
        userMenuOpen = !userMenuOpen;
    }
  
    function closeUserMenu() {
        userMenuOpen = false;
    }
  
    function handleClickOutside(event) {
        if (!event.target.closest('.user-menu') && !event.target.closest('.btn-user')) {
            closeUserMenu();
        }
    }
  
    onMount(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    });
  </script>
  
  <style>
    .btn-primary {
        display: block;
        width: 100%;
        padding: 10px;
        margin: 10px 0;
        color: white;
        background-color: #007bff;
        border: none;
        border-radius: 5px;
        text-align: center;
        cursor: pointer;
        font-size: 16px;
        transition: background-color 0.2s;
    }
    .btn-user {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 10px;
        margin: 10px 0;
        background: white;
        border: none;
        border-radius: 5px;
        text-align: center;
        cursor: pointer;
        font-size: 16px;
        transition: background-color 0.2s;
        position: relative; /* Added for positioning the pop-up menu */
    }
    .mobile-navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        width: 100%;
    }
    .logo {
        margin-right: 20px;
    }
    .hamburger {
        padding-right: 10px;
        color: #007bff;
        cursor: pointer;
    }
    .menu {
        position: fixed;
        top: 0;
        right: 0;
        width: 50%;
        height: 100%;
        background-color: white;
        transform: translateX(100%);
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        display: flex;
        flex-direction: column;
        padding-left: 10px;
        padding-right: 10px;
        z-index: 1000;
        box-shadow: none; /* Set box-shadow as none by default */
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
    }
    .menu.open {
        transform: translateX(0);
        box-shadow: -2px 0 5px rgba(0,0,0,0.3); /* Apply box-shadow when open */
    }
    .user-info {
        margin-top: auto;
        border-top: 1px solid #ddd;
        margin-bottom: 20px; /* Added margin for more space */
        text-align: center; /* Center align user info */
        position: relative;
    }
    .user-avatar {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 10px;
    }
    .user-avatar img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .shade {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 500;
        opacity: 0;
        transition: opacity 0.3s ease;
        pointer-events: none;
    }
    .shade.open {
        opacity: 1;
        pointer-events: all;
    }
    .menu-list {
        flex-grow: 1;
        overflow-y: auto;
        padding-top: 60px; /* Added padding to make space for close button */
    }
    .menu-list ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    .menu-list button {
        display: block;
        width: 100%;
        padding: 10px;
        margin: 10px 0;
        background: none;
        border: none;
        border-radius: 5px;
        text-align: left;
        padding-left: 30px;
        cursor: pointer;
        font-size: 16px;
        transition: background-color 0.2s;
    }
    .menu-list button:hover {
        background-color: #FAFAFA;
    }
    .close-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        background: none;
        border: none;
        font-size: 20px;
        cursor: pointer;
        color: #007bff;
    }
    .user-name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100px; /* Adjust this value as needed */
    }
    .user-menu {
        position: absolute;
        top: -70px; /* Adjust to position the menu above the button */
        left: 0;
        right: 0;
        background: white;
        border: 1px solid #ddd;
        border-radius: 5px;
        box-shadow: 0 2px 10px rgba(0,0,0,0.1);
        z-index: 1001;
        display: none;
    }
    .user-menu.open {
        display: block;
    }
    .user-menu button {
        display: block;
        width: 100%;
        padding: 10px;
        background: none;
        border: none;
        text-align: left;
        cursor: pointer;
        font-size: 14px;
        transition: background-color 0.2s;
    }
    .user-menu button:hover {
        background-color: #FAFAFA;
    }
  </style>
  
  <div class="mobile-navbar">
    <div class="logo" on:click={() => goToUrl('/')}>
        <img src="/images/logo_w_name.svg" alt="Logo" />
    </div>
    <div class="hamburger" style="z-index:1000" on:click={toggleMenu}>
        <i class="fa-solid fa-bars"></i>
    </div>
  </div>
  
  <div class="shade {isOpen ? 'open' : ''}" on:click={closeMenu}></div>
  
  <div class="menu {isOpen ? 'open' : ''}">
    <button class="close-btn" on:click={closeMenu}>
      <i class="fa-solid fa-times"></i>
    </button>
    <div class="menu-list">
        <button on:click={() => goToUrl('/chat')}>Features</button>
        <button on:click={() => goToUrl('/bookshelf')}>Bookshelf</button>
        <button on:click={() => goToUrl('/pricing')}>Pricing</button>
        <button on:click={() => goToUrl('/about')}>About</button>
    </div>
    <div class="user-info">
        {#if loggedIn && user}
            <button class="btn-user" on:click={toggleUserMenu}>
                <div class="user-avatar">
                    <img class="img-profile" src={user.profile_image} alt="Profile Image" />
                </div>
                <div class="user-name">
                    {user.name}
                </div>
            </button>
            <div class="user-menu {userMenuOpen ? 'open' : ''}">
                <button on:click={() => goToUrl('/dashboard')}>Account</button>
                <button on:click={() => goToUrl('/logout')}>Log out</button>
            </div>
        {:else}
            <button class="btn-primary" on:click={() => goToUrl('/login')}>Sign in</button>
        {/if}
    </div>
  </div>
  