<script>
  import { fade, fly } from 'svelte/transition';
  import { onMount, tick } from 'svelte';
  import Navbar from './Navbar.svelte';
  import MobileNavBar from './MobileNavBar.svelte';

  let loggedIn = false;
  let user = null;

  let features = [
      {
          src: 'images/chat',
          title: 'AI Tutor',
          description: 'to <span style="color:#256CFD">chat PDF</span> and explore latest research.',
          url: '/chat'
      },
      {
          src: 'images/write',
          title: 'AI Writer',
          description: 'to <span style="color:#256CFD">draft paper</span> with real references for you.',
          url: '/chat/draft'
      },
      {
          src: 'images/proofread',
          title: 'AI Proofreader',
          description: 'to <span style="color:#256CFD">proofread</span> with change tracking.',
          url: '/chat/proofread'
      },
      {
          src: 'images/review',
          title: 'AI Reviewer',
          description: 'to <span style="color:#256CFD">review</span> paper with insightful feedback.',
          url: '/chat/review'
      }
  ];
  let currentIndex = 0;
  let currentSrc;
  let interval;
  let paused = false;
  let displayedTitle = '';
  let typingDelay = 10; // Delay in milliseconds for each character

  onMount(() => {
      checkLoginStatus();
      startInterval();
      toggleImages(0)
      window.addEventListener('resize', updateSrc);
      updateSrc();
      return () => clearInterval(interval);
  });

  async function checkLoginStatus() {
        const response = await fetch('/check-login');
        const data = await response.json();
        loggedIn = data.loggedIn;
        if (loggedIn) {
            user = data.user;
        }
    }

  function updateSrc() {
    const width = window.innerWidth;
    currentSrc = width > 768 ? features[currentIndex].src + ".svg" : features[currentIndex].src + ".png";
  }

  function startInterval() {
      interval = setInterval(() => {
          if (!paused) {
              toggleImages();
          }
      }, 4500);
  }

  function toggleImages(newIndex = (currentIndex + 1) % features.length) {
      currentIndex = newIndex;
      updateSrc();
      typewriterEffect(features[currentIndex].description);
  }

  function goToUrl(url) {
    window.location.href = url;
  }

  async function typewriterEffect(title) {
        displayedTitle = ''; // Clear previous title
        for (let i = 0; i <= title.length; i++) {
            displayedTitle = title.slice(0, i);
            await new Promise(resolve => setTimeout(resolve, typingDelay));
        }
    }

  function handleMouseEnter(index) {
      paused = true;
      toggleImages(index);
  }

  function handleMouseLeave() {
      paused = false;
  }
</script>

<style>
  .container {
    position: relative;
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
  }

  .button-container {
    position: absolute;
    bottom: 8%;
    text-align: center;
    width: 100%;
  }

  .img-feature {
    position: absolute;
    left: 45%;
    top: 8vh;
    max-height: 70vh;
    z-index: -1;
    transition: transform 0.5s ease-out;
  }

  .slogan-container {
    position: relative;
    padding-top: 200px;
    padding-left: 20px;
    padding-right: 70px;
  }

  .btn-start {
    background: var(--colors-brand-50, #256CFD);
    color: white;
    border-radius: 8px;
    padding-top: 22px;
    padding-bottom: 22px;
    padding-left: 40px;
    padding-right: 30px;
    font-size: 20px;
    border: none;
    cursor: pointer;
  }

  .btn-feature {
    border-radius: 30px;
    padding-top: 18px;
    padding-bottom: 18px;
    margin: 15px;
    width: 300px;
    border: 1px solid rgba(255, 255, 255, 0.70);
    background-color: transparent;
    color: rgba(255, 255, 255, 0.70);
    cursor: pointer;
    font-weight: bold;
    font-size: 22px;
    display: inline-block;
    text-align: center;
  }

  .active {
    border: 1px solid white;
    background-color: rgba(74, 129, 237, 0.40);
    text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.50);
    color: white !important;
  }

  .slogan {
    max-width: 100%;
  }

  .description-container {
    padding-top: 25px;
    font-size: 25px;
    height: 70px;
    font-weight: bold;
    max-width: 500px;
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background-image: url('/images/bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
    z-index: -100;
  }

  .desktop-navbar {
    display: block;
  }

  .mobile-navbar {
    display: none;
  }

  @media (max-width: 768px) {
    .container {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    .slogan-container {
      padding-top: 100px;
    }
    
    .description-container {
      max-width: 100%;
      padding-top: 15px;
      font-size: 15px;
      height: 40px;
    }

    .img-feature {
      left: auto;
      right: 0;
      top: 280px;
      max-width: 85%;
    }

    .button-container {
      bottom: 10px;
    }

    .btn-feature {
      border-radius: 10px;
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 5px;
      padding-right: 5px;
      margin: 5px;
      width: auto;
      font-size: small;
      margin-bottom: 15px;
    }

    .btn-start {
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 20px;
      padding-right: 20px;
    }

    .desktop-navbar {
      display: none;
    }

    .mobile-navbar {
      display: block;
    }
  }
</style>

<div class="background"></div>

{#key currentIndex}
  <img src={currentSrc}
       alt="{features[currentIndex].title}"
       class="img-feature"
       in:fly={{ y: 30, duration: 500 }}
       out:fade={{ duration: 500 }} />
{/key}

<div class="desktop-navbar">
  <Navbar {loggedIn} {user}/>
</div>
<div class="mobile-navbar">
  <MobileNavBar {loggedIn} {user}/>
</div>
<div class="container">

  <div class="slogan-container">
    <img class="slogan" src="/images/slogan.svg" alt="slogan">
    <div class="description-container">
      {@html displayedTitle}
    </div>
    <button class="btn-start" on:click={() => goToUrl('/chat')}>Start now&nbsp;&nbsp;→</button>
  </div>
</div>

<div class="button-container">
  {#each features as feature, index}
    <div class="btn-feature"
         class:active={index === currentIndex}
         on:mouseenter={() => handleMouseEnter(index)}
         on:mouseleave={handleMouseLeave}
         on:click={() => goToUrl(feature.url)}
         >
      {feature.title}
    </div>
  {/each}
</div>
